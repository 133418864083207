<template>
  <div class="body">
    <div class="filter-block">
      <b-input-group style="margin: 5px 10px; max-width: 250px; ">
        <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4"/>
        <b-input-group-append>
          <b-btn size="sm" :disabled="!filter" @click="filter = ''" ><b-icon-backspace></b-icon-backspace></b-btn>
        </b-input-group-append>
      </b-input-group>
      <b-input-group style="margin: 5px 10px; max-width: 250px;" v-if='(isBank||isOwner)&&!isCollector'>
        <b-form-select v-model="dealer" :options="dealers" size="sm" style="background-color: #c6ced4">
          <template slot="first">
            <option :value="null" >-- Дилер --</option>
          </template>
        </b-form-select>
        <b-input-group-append>
          <b-btn size="sm" :disabled="!dealer" @click="dealer = null"><b-icon-backspace></b-icon-backspace></b-btn>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="info">
      <b-btn class='button' size="sm" v-on:click="querytabledata" style="margin: 0"> <b-icon-arrow-clockwise> </b-icon-arrow-clockwise> </b-btn>
    </div>
    <div class="" v-if="false">
      <GmapMap
        :center="{lat:42.871183, lng:74.604892}"
        :zoom="13"
        map-type-id="terrain"
        style="width: 100%; height: 500px; margin: 10px"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @click="center=m.position"
          :title="m.title"
        />
      </GmapMap>
    </div>
    <div class="column">
      <ul>
        <li v-for="(item) in items" v-bind:key="item.id">
          <div class="li-name">
            <span class="li-name">
              <span>{{item.terminal}}</span>
              <div class="term-info"> Адрес: {{item.address}}</div>
              <div class="term-summa">{{item.summa.replace(/\.\d+$/,'').replace(/(.)(?=(\d{3})+$)/g,'$1 ')+' сом'}}</div>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';
import terminals from '../_helpers/terminals.json'

export default {
  props: {
    name: {
      type: String,
      default: 'terminals'
    }
  },
  data: () =>  ({
    isBusy: false,
    items: [],
    selectedStatus: ['warning','error'],
    count: {ok: 0, warning: 0, error:0, blocked: 0, nosignal: 0, noip: 0},
    fields: [
      { label: 'ID', key: "id", sortable: true, 'class': 'text-left' },
      { label: 'Терминал', key: "name", 'class': 'text-left' },
      { label: 'Статус', key: "status", 'class': 'text-left' },
    ],
    filter: '',
    dealer: null,
    dealers:[],
    markers:[]
  }),
  computed: {
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isCollector () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='collector'
      else return false
    },
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
  },

  methods: {
    filterItems(items) {
      return items.filter(item => (this.selectedStatus.includes(item.status) && (item.name==null ? '' : item.name).includes(this.filter) && (this.dealer==null ? this.dealer: item.dealerid)==this.dealer));
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    countState() {
      this.items.forEach();
    },
    querytabledata () {
      this.count= {ok: 0, warning: 0, error:0, blocked: 0, nosignal: 0, noip: 0};
      axios
          .post(`${config.apiUrl}/query/getdata`,{ DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                  type: ['EncashmentList'], partnertype: this.$store.state.authentication.user.partnertype,
                                                  count: 200, summa: 30000,
                                                  role: this.$store.state.authentication.user.role, token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.items = response.data;
                  this.totalRows = this.items.length;
                })
          .catch(e => {alert (e); return [e]})
    },
    getDealers () {
      axios
      .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                    partnertype: this.$store.state.authentication.user.partnertype,
                                                    bankId: '',
                                                    type: ['Dealers'], status: false,
                                                    token: this.$store.state.authentication.user.token})
      .then(response => {
        this.dealers = response.data;
        this.dealers.forEach((item,index) => {
          Object.assign(this.dealers[index], {value:item.id}, {text: item.name})
        })
      })
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },
  },
  created() {
    this.querytabledata ();
    this.getDealers ();
    terminals.forEach((item) => {
      this.markers.push({title: `${item.name}\n${item.address}`, position: {lat: item.lat, lng: item.lng}})
    });

  }
}
//<b-table striped hover :items="items" :fields="fields"></b-table>
</script>
<style scoped>
.body {
  background-color: #21252b;
  font-size: 14px;
}
.info {
  padding-bottom: 10px;
  text-align: center;
}
.filter-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}
.info-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}
.column {
  -webkit-columns: 200px 4;
  -moz-columns: 200px 4;
  columns: 300px;
  column-gap: 0;
}
.first {
  display: block;
  margin: 2px 0px;
  height: 2em;
  line-height: 2em;
  min-width: 100px;
  color: #ccc;
}
ul {
  list-style-type: none;
  padding-inline-start: 0 !important;
  padding: 0;
  margin: 0 10px;
}
li {
  display: flex;
  margin-bottom: 10px;
}
.li-name {
  display: inline-block;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;

  width: 100%;
  color: #ccc;
  background-color:  #383e4a;
  padding-left: 5px;
}
.menu-icon {
  align-items: flex-end;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?88919010#fontello') format('svg');
  }
}
*/
.term-info  {
  color: #17a2b8;
  font-size: 9pt;
  background-color:  #383e4a;
}
.term-summa  {
  color: orange;
  font-size: 9pt;
  background-color:  #383e4a;
  text-align: right;
  padding-right: 5px;
}
.errorColor{color: #ff4a46;}
.okColor {color: green;}
.lockOkColor {color: blue;}
.lockWarningColor {color: peru;}
.noSignal {color: gray;}
.warningColor {color: orange;}
.terminalColor {background-color:  #494e56}
.activeTerminalColor {background-color:  #383e4a}
</style>
